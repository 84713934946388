.drag-item {
    border: 1px solid #ccc;
    margin: 5px 0px;
    padding: 5px;
}

.drag-item span {
    float: right;
    line-height: 22px;
    font-size: 13px;
}

.drag-item span.anticon.anticon-menu.menuicon {
    float: left;
    font-size: 10px;
    line-height: 22px;
    margin-right: 5px;
}
.drag-item:after{
    clear: both;
    display: block;
    content: "";
}