.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.ant-picker-range {
  display: flex;
}

.ant-input-group.cstm_padding-row {
  margin: 0 -10px;
  display: flex;
  width: auto;
}

.cstm_padding-row_nongutter {
  display: flex;
  width: auto;
}

.ant-modal-header {
  background: #ffffff;
  padding: 10px 20px;
}
.ant-modal-footer {
  background: #ffffff;
  padding: 10px 20px 40px;
}
.bookvehicle_modal .ant-modal-header {
  padding: 10px 20px;
}
.bookvehicle_modal .ant-modal-close-x {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookvehicle_modal .deletebtn {
  position: absolute;
  top: 8px;
  padding: 0px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: red;
}
.bookvehicle_modal .ant-modal-title {
  text-align: center;
}

.bookvehicle_modal .ant-modal-footer {
  text-align: center;
}
.bookvehicle_modal .ant-modal-body {
  padding: 12px 20px;
}

.bookvehicle_modal .ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.bookvehicle_modal .ant-form-vertical .ant-form-item {
  margin-bottom: 10px;
}

.review-table {
  font-size: 16px;
}

.review-table label {
  text-align: right;
  display: block;
}

.review-table span {
  display: block;
  font-weight: 700;
}

.review-table .ant-row {
  margin-bottom: 20px;
}
.review-table .ant-row:last-child {
  margin-bottom: 0;
}
.fc-toolbar button.fc-today-button {
  text-transform: capitalize;
}
.fc .fc-button-primary:focus {
  background-color: #1e2b37;
  border-color: #1a252f;
  box-shadow: none;
}

.ant-modal.bookvehicle_modal {
  width: 100% !important;
  max-width: 600px;
}
.cal-max-height .fc-theme-standard .fc-view-harness {
  height: 428px !important;
}
.site-calendar-demo-card .fc .fc-view-harness {
  height: 268.037px !important;
}
.right-calender .fc-button {
  background-color: transparent !important;
  border: 1px solid #d9d9d9;
  color: #333 !important;
}
.right-calender .fc-button.fc-button-active {
  background-color: transparent !important;
  color: #1890ff !important;
  border: 1px solid #1890ff !important;
}
.right-calender .fc-button .fc-icon {
  color: #333;
}
.right-calender table,
.right-calender table th,
.right-calender table td {
  border: none !important;
  text-align: right;
}
.right-calender table a {
  color: #333;
  font-weight: 400;
}
.right-calender table td .fc-daygrid-day-frame {
  border-top: 2px solid #f0f0f0;
  margin: 0 4px;
  transition: all 0.3s;
}
.right-calender
  table.fc-scrollgrid-sync-table
  td.fc-daygrid-day:hover
  .fc-daygrid-day-frame {
  background-color: #f5f5f5;
}
.right-calender table.fc-scrollgrid-sync-table .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.right-calender
  table.fc-scrollgrid-sync-table
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame {
  background-color: #e6f7ff;
}
.right-calender
  table.fc-scrollgrid-sync-table
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  a {
  color: #1890ff;
}
.right-calender
  table.fc-scrollgrid-sync-table
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame {
  border-top: 2px solid #1890ff;
}
.right-calender table td .fc-daygrid-day-frame .fc-daygrid-day-events {
  min-height: 80px;
}
@media only screen and (max-width: 1199px) {
  .fc .fc-button {
    padding: 4px;
    font-size: 14px;
  }
  .fc .fc-toolbar-title {
    font-size: 18px;
    margin: 0;
  }
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .dash-calender .fc .fc-scrollgrid-section table {
    width: 100% !important;
  }
  .dash-calender .fc-col-header {
    width: 100% !important;
  }
  .dash-calender .fc .fc-scrollgrid-section-body table,
  .dash-calender .fc .fc-scrollgrid-section-footer table {
    width: 100% !important;
  }

  .dash-calender .fc .fc-daygrid-body {
    width: 100% !important;
  }
  .cal-max-height .fc-theme-standard .fc-view-harness {
    height: 200px !important;
  }
}

@media only screen and (max-width: 575px) {
  .dash-calender .fc .fc-scrollgrid-section table {
    width: 100% !important;
  }
  .dash-calender .fc-col-header {
    width: 100% !important;
  }
  .dash-calender .fc .fc-scrollgrid-section-body table,
  .dash-calender .fc .fc-scrollgrid-section-footer table {
    width: 100% !important;
  }

  .dash-calender .fc .fc-daygrid-body {
    width: 100% !important;
  }
  .right-calender .fc .fc-toolbar-title {
    font-size: 15px;
  }
  .right-calender .fc .fc-button {
    padding: 5px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 480px) {
  .dash-calender .fc .fc-scrollgrid-section table {
    width: 100% !important;
  }
  .dash-calender .fc-col-header {
    width: 100% !important;
  }
  .dash-calender .fc .fc-scrollgrid-section-body table,
  .dash-calender .fc .fc-scrollgrid-section-footer table {
    width: 100% !important;
  }

  .dash-calender .fc .fc-daygrid-body {
    width: 100% !important;
  }
  .right-calender .fc .fc-toolbar-title {
    font-size: 15px;
  }
  .right-calender .fc .fc-button {
    padding: 4px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 350px) {
  .dash-calender .fc .fc-scrollgrid-section table {
    width: 100% !important;
  }
  .dash-calender .fc-col-header {
    width: 100% !important;
  }
  .dash-calender .site-calendar-demo-card .fc {
    min-height: 280px;
  }

  .dash-calender table.fc-scrollgrid.fc-scrollgrid-liquid {
    height: 100%;
    min-height: 148px;
  }
  .dash-calender a.fc-col-header-cell-cushion {
    font-size: 12px;
  }
  .dash-calender a.fc-daygrid-day-number {
    font-size: 12px;
  }
  .dash-calender h2.fc-toolbar-title {
    font-size: 18px;
  }
  .dash-calender .fc .fc-scrollgrid-section-body table,
  .dash-calender .fc .fc-scrollgrid-section-footer table {
    width: 100% !important;
  }

  .dash-calender .fc .fc-daygrid-body {
    width: 100% !important;
  }
  .right-calender .fc .fc-toolbar-title {
    font-size: 15px;
  }
  .right-calender .fc .fc-button {
    padding: 4px;
    font-size: 13px;
  }
}
