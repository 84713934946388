.autoexport-wrap{
    /*width: 60%;*/
    margin: auto;
}

.form-bg {
    margin: 0 0 31px 0;
    padding: 10px 10px 0 10px;
    /* background: #fbfbfb; */
    border: #eaeaea solid 1px;
    border-radius: .18rem;
}

.intervale-div .ant-row.ant-form-item {
    margin-bottom: 5px;
}

.selectVDO-row .ant-row.ant-form-item{
    margin-bottom: 0px;
}

p.selectVDO-p {
    color: #737373;
    margin-top: 5px;
    font-size: .8rem;
    margin-bottom: 20px;
    line-height: 1.2;
}

.button-div{
    text-align: center;
}

li.ant-list-item.autoexport-listitem:hover {
    background: #a7a7a71f;
    cursor: pointer;
}
