.subsubmenu > .ant-menu-submenu-title{
    padding-left: 20px !important;
}
.subsubmenu > ul > li.ant-menu-item{
    padding-left: 42px !important;
}
.ant-layout-sider-children {
    height: 100%;
    overflow: auto;
    position: relative;
  }