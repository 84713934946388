.site-layout-background {
    background-color: white !important;
}

.ant-list-item {
    background: white;
    padding: 17px 21px !important;
    box-shadow: 3px 4px 16px 0 rgb(0 0 0 / 5%);
}

.point {
    cursor: pointer;
}


.main-wrapper {
    /*padding: 20px !important;*/
    margin-top: 10px;
}

.label-div {
    display: flex !important;
}

.ant-picker {
    width: 86%;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    color: white !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    color: black !important;
}

.ant-select-arrow {
    color: black !important;
}

.table-link {
    color: #1890ff !important;
}

.ant-list-vertical .ant-list-item-action {
    margin-top: 0 !important;
}