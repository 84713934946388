/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.field {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field .label,
.field .number {
  font-size: 14px;
  line-height: 22px;
}
.field .number {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
}
