.ant-layout-sider  {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: fixed; 
  top: 0;
  left: 0;
  width: max-content;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 32px;
  margin: 16px;
  width: auto;
}

.ant-layout-sider .ant-menu-root {
  margin-top: -4px;
}

.ant-layout.site-layout header.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px !important;
  background-color: #fff;
}

.ant-layout.site-layout header.ant-layout-header > span {
  margin-left: 30px;
}

a.ant-dropdown-trigger.ant-dropdown-link.notification {
  margin-right: 20px;
}

.ant-dropdown-menu-item > a:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-layout.site-layout header.ant-layout-header .trigger {
  justify-self: flex-start;
  margin-right: auto;
  margin-left: 0;
  z-index: 11;
}
.ant-layout-header .notification .ant-badge {
  vertical-align: middle;
}
.ant-layout-header .notification .ant-badge > a {
  display: block;
}
.ant-layout-header .notification span[role="img"] svg {
  width: 100%;
  height: 100%;
}
.ant-layout-header .notification span[role="img"] {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.ant-layout-sider {
  top: 0;
  left: 0;
  overflow: auto;
}
ul.ant-menu.navbar4 {
  border-top: 1px solid #1e344a;
  padding-top: 0;
  margin-top: 1rem;
}
.navbar1,
.navbar2 {
  padding-bottom: 1rem;
}
.favriot-drawer .ant-drawer-body {
  padding: 0px 24px 10px;
}
.fun-list p {
  display: inline-block;
  width: 95%;
}

.fun-list h3 {
  margin-top: 30px;
}
.addfav {
  background: transparent;
  border: none;
  color: #fff;
  padding: 0 calc(50% - 16px / 2);
}
.addfav:hover {
  background: transparent;
}
.ant-menu-submenu-popup .ant-menu-sub li.ant-menu-item {
  display: flex;
  align-items: center;
}
.ant-menu-submenu-popup .ant-menu-sub li.ant-menu-item span {
  /*margin: 0 0 0 auto !important;*/

  margin: 0 0 0 0 !important;
  padding-left: 1rem;
}
.headerbutton {
  margin-right: 10px;
}
.headerbutton:hover,
.headerbutton:focus {
  background: #001529;
  /* color: #ffff; */
  border-color: #fff;
}
.widgetsblock .widgetsleft,
.widgetsblock span {
  display: inline-block;
  /* padding: 0 10px; */
}
.widgets-drawer h3 {
  margin-bottom: 20px;
}
.widgetsleft {
  width: 95%;
}
.widgetsleft span {
  font-size: 30px;
}
.plus-addwidgets:hover {
  color: blue;
}
.widgetsdetails {
  padding-left: 10px;
}
.widgetsdetails h4 {
  margin-bottom: 0;
}
.widgetsdetails p {
  color: #888;
  margin-bottom: 10px;
}
.widgetsblock {
  margin-bottom: 15px;
}
.widgetsleft span,
.widgetsleft .widgetsdetails {
  display: inline-block;
}

.ant-layout-sider-dark.ant-layout-sider-collapsed + .site-layout {
  padding-left: 0;
  transition: ease-in-out 0.5s;
}
.ant-layout-sider-dark + .site-layout {
  /*padding-left: 120px;*/
  transition: ease-in-out 0.5s;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
}

a.ant-dropdown-trigger.ant-dropdown-link,
a.ant-dropdown-trigger.ant-dropdown-link:hover {
  color: #000;
}
.ant-layout-header a {
  margin-left: 10px;
  /* color: #fff; */
}
.ant-layout-header a:hover {
  /* color: #fff; */
}
.CreateGroup {
  cursor: pointer;
  /* background: #eee; */
  border: 0;
  display: block;
  width: 100%;
  padding: 6px;
  margin: 5px 0;
  border-radius: 5px;
}
.creactgroup-div {
  display: flex;
  align-items: center;
}
.ant-menu-item.submenuclass {
  padding-left: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #000 !important;
  border: 1px solid #000 !important;
  border-radius: 2px !important;
  /* color: #fff !important; */
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  /* color: #fff !important; */
}

.ant-select-arrow {
  /* color: #fff !important; */
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  /* color: #fff !important; */
  background-color: #000 !important;
}

.addwidgetbtn span,
.changelayoutbtn span {
  display: block;
}

.addwidgetbtn .addWidgetsIcon,
.changelayoutbtn .changeLayoutIcon {
  display: none;
}

.ant-input-search.searchInput {
  width: 140px !important;
}

/***  Responsive  ***/
@media only screen and (max-width: 1599px) {
}

@media only screen and (max-width: 1429px) {
}

@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 991px) {
  .ant-layout-header .ant-avatar-lg {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
  }
  .ant-layout-header .ant-avatar-lg.ant-avatar-icon {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-layout-header .ant-avatar-lg {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
  }
  .ant-layout-header .ant-avatar-lg.ant-avatar-icon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .ant-layout-header .notification span[role="img"] {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .ant-layout-header .notification span[role="img"] {
    width: 20px;
    height: 20px;
  }
  .widgetsleft {
    width: 94% !important;
  }
  .addwidgetbtn span,
  .changelayoutbtn span {
    display: none !important;
  }

  .addwidgetbtn .addWidgetsIcon,
  .changelayoutbtn .changeLayoutIcon {
    display: block !important;
  }
  .headerbutton {
    margin-right: 5px !important;
    padding: 5px !important;
  }
  .ant-select.langselectdropdown {
    font-size: 12px !important;
    width: 70px !important;
  }
}

@media only screen and (max-width: 350px) {
  .ant-layout-header .notification span[role="img"] {
    width: 20px;
    height: 20px;
  }
  .fc .fc-scrollgrid-section table {
    width: 100%;
  }

  .fc .fc-daygrid-body {
    width: 100%;
  }
}
/***  Responsive  ***/
.btnDD{color:#000;}