.ant-breadcrumb a {
    color: rgba(0, 0, 0, .45) !important;
}

.ant-breadcrumb-separator {
    color: rgba(0, 0, 0, .45) !important;
}

.header {
    position: relative !important;
}

.header h1 {
    margin-top: 11px;
}

.filter-btn {
    position: absolute !important;
    top: 18px !important;
    right: 23px !important;
}

.filter-btn-float {
    position: fixed !important;
    top: 288px !important;
    right: 0 !important;
    z-index: 999 !important;
    width: 45px !important;
}
.site-tree-search-value {
    color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
color: #d84a1b;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background-color: #bae7ff !important;
}
.ant-tree .ant-tree-node-content-wrapper {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}