.advancedSearch-btn {
    margin-top: 30px;
    color: #40A9FF;
    border: none;
    box-shadow: none;
    /* text-decoration: underline; */
}

.search-form-row {
    row-gap: 0px !important;
}

