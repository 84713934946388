button.ant-btn {
    margin-bottom: 10px;
    float: right;
}

.openMapModal .ant-modal-header, .openMapModal .ant-modal-footer {
    background: #ffffff;
    padding: 10px 15px;
}
.openMapModal p {
    margin-bottom: 0;
}

.openMapModal .header-div{
    border-right: 1px solid #ccc;
}

.heiDwS {
    white-space: nowrap;
    -webkit-flex: 0 0 100px !important;
    -ms-flex: 0 0 100px !important;
    flex: 0 0 100px !important;
}

.iEmSDR {
    white-space: nowrap;
    font-weight: 400;
    min-width: 130px !important;
    -webkit-flex: 0 0 130px !important;
    -ms-flex: 0 0 130px !important;
    flex: 0 0 130px !important;
}

.eiJuD {
    white-space: nowrap;
    flex: 0 0 130px !important;
}

.eacjiz {
    white-space: nowrap;
    font-weight: 400;
    min-width: 130px !important;
    flex: 0 0 130px !important;
}

.sc-iCfMLu.Vntsa span {
    margin-right: 7px !important;
}