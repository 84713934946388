.site-card-wrapper {
    margin-bottom: 31px;
}
.card_body{
    text-align: center;
    border-radius: 5px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
}

.card_header{
    font-weight: 600;
    font-size: 24px;
}
.travel-card{
    background: #d6ecfb !important;
    border: 2px solid #87c8f4 !important;
}

.hours-card{
    background: #ffe0e6 !important;
    border: 2px solid #ffa9bb !important;
}

.distance-card{
    background: #ffecd9 !important;
    border: 2px solid #ff9f40 !important;
}