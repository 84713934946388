#gauge-demo {
    height: 200px;
    /* height: 500px; */
  }
  
  #gauge-container {
    text-align: center;
    /* margin: 20px auto; */
    /* background-image: url('https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/Gauges/mask.png'); */
    /* background-repeat: no-repeat; */
    /* width: 786px; */
    width: 100%;
    /* height: 140px; */
  }
  
  #gauge-container > div {
    display: inline-block;
    position: relative;
  }
  
  .left-section {
    top: -20px;
    left: -40px;
  }
  
  .center-section {
    /* top: 25px; */
  }
  
  .right-section {
    top: -20px;
    right: -40px;
  }
  
  .speed-value {
    position: absolute;
    border: 2px solid #f05b41;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    left: 28%;
    top: 36%;
    line-height: 100px;
  }
  
  .speed-value > span {
    font-size: 50px;
    font-weight: lighter;
    color: #f05b41;
  }
  
  #fuel-gauge {
    position: absolute;
    left: 34%;
    bottom: 5%;
  }
  
  #slider {
    position: relative;
    /* top: -105px; */
    margin: 0 auto;
  }
  
  #slider .dx-slider-bar {
    background-color: #fff;
  }
  
  #slider .dx-slider-handle {
    background-color: #f05b41;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-right: -8px;
    border-radius: 50%;
    border: none;
  }
  
  #slider .dx-slider-handle .dx-inkripple-wave {
    background: none;
  }
  
  #slider .dx-slider-handle::after {
    background-color: #f05b41;
  }
  
  #slider .dx-slider-range.dx-slider-range-visible {
    border-color: #f05b41;
    background-color: #f05b41;
  }
  