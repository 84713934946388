@import "~antd/dist/antd.min.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.color-desc-p {
  font-size: 12px;
  color: #999;
}
/*.ant-layout .site-layout .content {
  padding: 20px !important;
  min-height: 593px;
}*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.h-100 {
  height: 100% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.inset-box-shadowed-effect {
  /* box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important; */
  border: none;
  border-radius: 5px;
  background: #fff;
}

#root > div {
  height: 100%;
}

.p-4 {
  padding: 15px;
}

@media only screen and (max-width: 1599px) {
}

@media only screen and (max-width: 1429px) {
}

@media only screen and (max-width: 1199px) {
  /* .dashboard-table > div {
    width: 49%;
    margin-right: 10px;
  }
  .dashboard-table > div:last-child {
    margin-right: 0px;
  } */
}

@media only screen and (max-width: 991px) {
  .ant-layout-sider-zero-width-trigger {
    top: 10px;
  }
  /* .dashboard-table > div {
    width: 49%;
    margin-right: 10px;
  }
  .dashboard-table > div:last-child {
    margin-right: 0px;
  } */
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
  /*.ant-layout .site-layout .content {
    padding: 20px !important;
    min-height: 300px !important;
  }*/
}

@media only screen and (max-width: 350px) {
}

/***  Responsive  ***/

.customizebtn {
  position: fixed !important;
  right: 0;
  top: 250px;
  z-index: 99;
}

.access-list-wrapper {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
}

.access-list-wrapper .ant-checkbox-group-item {
  padding: 7px 10px;
  border-bottom: 1px solid #d5d5d5;
  width: 100%;
}

.access-list-wrapper .ant-checkbox-group-item:last-child {
  border-bottom: none;
}

.access-list-wrapper .ant-checkbox-group-item.access-list-head {
  background: #00000014;
}